import { ButtonPrimary } from 'components/Button'
import { Text } from 'rebass'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 964px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};
`

export const ReferralLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 10px 16px;
  background: var(--Grey-1, #252525);

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 16px;
    align-items: start;
  `};
`

export const ReferralLink = styled(Text)`
  background: linear-gradient(90deg, #d0d0d0 62.5%, rgba(208, 208, 208, 0) 96.54%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const CopyButton = styled(ButtonPrimary)`
  padding-inline: 8px;
`

export const CopyLogo = styled.img`
  height: 16px;
  margin-right: 8px;
`

export const ReferralSummaryItem = styled.div`
  padding: 22px;
  width: 300px;
  border: 1px solid var(--white-20, #666);
  background: black;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 32px;
  `};
`

export const ReferralSummaryContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: column;
  `};
`

export const TiersContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 24px;
    margin-bottom: 0px;
  `};
`

export const Tier = styled.div<{ selected: boolean; isLast: boolean }>`
  margin-right: ${({ isLast }) => (isLast ? '0px' : '24px')};
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 64px;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.primary1 : '#666666')};
  background: ${({ selected }) => (selected ? '#3C4E31' : 'black')};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ selected, theme }) => (selected ? theme.primary1 : '#666')};
  :hover {
    cursor: pointer;
    border: 1px solid ${({ selected, theme }) => (selected ? theme.primary1 : 'white')};
    color: ${({ selected, theme }) => (selected ? theme.primary1 : 'white')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 8px 16px;
    font-size: 14px;
  `};
`

export const Leaderboard = styled.div`
  margin-bottom: 64px;
  padding: 32px;
  width: 100%;
  border: 1px solid var(--white-20, #666);
  background: #000;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 8px;
  `};
`

export const LeaderboardLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 14px;
    justify-content: space-between;
  `};
`

export const LeaderboardItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #2b2b2b;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 14px;
    padding-bottom: 10px;
    justify-content: space-between;
  `};
`

export const LeaderboardItem = styled.div<{ isIndex?: boolean }>`
  width: 200px;
  margin-right: 32px;
  ${({ theme, isIndex }) => theme.mediaWidth.upToMedium`
    margin-right: 8px;
    width: 100px;
    ${isIndex &&
      css`
        width: 32px;
      `}
`};
`

export const PageNavigator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`

export const NavigateButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  :hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 20px;
    height: 20px;
  `};
`

export const PageNumber = styled.div<{ selected: boolean; isLast: boolean }>`
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${({ selected }) => (selected ? 'white' : '#666')};
  margin-left: 16px;
  margin-right: ${({ isLast }) => (isLast ? '16px' : '0px')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
`};
`

export const Arrow = styled.img`
  height: 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 12px;
    height: 12px;
  `};
`
