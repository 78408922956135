import React, { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'

import { ThemeContext } from 'styled-components'
import {
  Container,
  LoyaltySummaryContainer,
  LoyaltySummaryItem,
  History,
  HistoryLabelRow,
  HistoryItem,
  HistoryItemRow,
  PageNavigator,
  NavigateButton,
  PageNumber,
  Arrow,
  LotaltyInfo,
  LotaltyInfoLogo,
  LotaltyInfoContainer
} from './styleds'
import FileLogo from '../../assets/images/ic-file.svg'
import CalendarLogo from '../../assets/images/ic-calendar.svg'
import ArrowLeft from '../../assets/images/ic-pointer-left.svg'
import ArrowRight from '../../assets/images/ic-pointer-right.svg'
import Row from 'components/Row'
import { ButtonGray, ButtonPrimary } from 'components/Button'

const HistoryLabels = ['Date', 'Epoch', 'Rewards']

export default function Loytalty() {
  const theme = useContext(ThemeContext)
  const [selectedPage, setSelectedPage] = useState(0)

  const leaderboard = useMemo(() => {
    return Array(35)
      .fill(null)
      .map((_, index) => ({
        id: index,
        date: '2023-08-30',
        epoch: '2023-07-30 to 2023-08-30',
        rewards: 1000
      }))
  }, [])

  const leaderboardDisplayData = useMemo(() => {
    const page = [...leaderboard].splice(selectedPage * 10, 10)

    return {
      page,
      pageCount: Math.ceil(leaderboard.length / 10)
    }
  }, [leaderboard, selectedPage])

  const onClickPrevious = () => {
    if (selectedPage === 0) {
      return
    }
    setSelectedPage(prev => --prev)
  }

  const onClickNext = () => {
    if (selectedPage === leaderboardDisplayData.pageCount - 1) {
      return
    }
    setSelectedPage(prev => ++prev)
  }

  return (
    <Container>
      <Text fontWeight={400} fontSize={[24, 24, 32]} color={theme.primary1}>
        Loyalty Program
      </Text>
      <Text
        fontWeight={400}
        fontSize={[14, 14, 16]}
        marginTop={21}
        marginBottom={16}
        color="#C2C2C2"
        textAlign="center"
      >
        PixelSwap will be distributing a portion of its $PIXEL tokens to the community through our newly introduced
        loyalty program!
      </Text>
      <Text fontWeight={400} fontSize={[14, 14, 16]} color="#C2C2C2" textAlign="center">
        For more information, please read the loyalty program details.
      </Text>
      <LotaltyInfoContainer>
        <LotaltyInfo>
          <Row width="auto">
            <LotaltyInfoLogo src={FileLogo} />
            <Text fontWeight={400} fontSize={[14, 14, 16]} color="#C2C2C2" textAlign="center" marginX={2}>
              Rewards:
            </Text>
          </Row>
          <Text fontWeight={400} fontSize={[14, 14, 16]} color="white" textAlign="center">
            $1 Swap volume = $1 yPixel
          </Text>
        </LotaltyInfo>
        <LotaltyInfo>
          <Row width="auto">
            <LotaltyInfoLogo src={CalendarLogo} />
            <Text fontWeight={400} fontSize={[14, 14, 16]} color="#C2C2C2" textAlign="center" marginX={2}>
              Rewards:
            </Text>
          </Row>
          <Text fontWeight={400} fontSize={[14, 14, 16]} color="white" textAlign="center">
            $1 Swap volume = $1 yPixel
          </Text>
        </LotaltyInfo>
      </LotaltyInfoContainer>
      <LoyaltySummaryContainer>
        <LoyaltySummaryItem>
          <Text fontWeight={400} fontSize={16} marginBottom={16} color="white">
            yPixel rewards per Epoch
          </Text>
          <Text fontWeight={400} fontSize={32} color="white">
            $1.000,00
          </Text>
          <ButtonPrimary width="auto" height={40} marginTop={24}>
            Claim 2d : 5h : 30m
          </ButtonPrimary>
        </LoyaltySummaryItem>
        <LoyaltySummaryItem>
          <Text fontWeight={400} fontSize={16} marginBottom={16} color="white">
            Swap volume per Epoch
          </Text>
          <Text fontWeight={400} fontSize={32} color="white">
            $1.000,00
          </Text>
          <ButtonGray width="auto" height={40} marginTop={24}>
            Go to Swap
          </ButtonGray>
        </LoyaltySummaryItem>
      </LoyaltySummaryContainer>
      <Text fontWeight={400} fontSize={32} color="#C2C2C2" marginTop={[16, 16, 50]} marginBottom={32}>
        Rewards History
      </Text>
      <History>
        <HistoryLabelRow>
          {HistoryLabels.map((label, index) => {
            return (
              <HistoryItem key={label} isDate={index === 0}>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="#C2C2C2">
                  {label}
                </Text>
              </HistoryItem>
            )
          })}
        </HistoryLabelRow>
        {leaderboardDisplayData.page.map(info => {
          return (
            <HistoryItemRow key={info.id}>
              <HistoryItem isDate>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="white" width="auto">
                  {info.date}
                </Text>
              </HistoryItem>
              <HistoryItem>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="white" width="auto">
                  {info.epoch}
                </Text>
              </HistoryItem>
              <HistoryItem>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="white" width="auto">
                  ${info.rewards} yPixel
                </Text>
              </HistoryItem>
            </HistoryItemRow>
          )
        })}
        <PageNavigator>
          <NavigateButton onClick={onClickPrevious}>
            <Arrow src={ArrowLeft} />
          </NavigateButton>
          <Row width="auto">
            {Array(leaderboardDisplayData.pageCount)
              .fill(null)
              .map((_, index) => {
                const selected = selectedPage === index
                const isLast = index === leaderboardDisplayData.pageCount - 1
                return (
                  <PageNumber key={index} selected={selected} isLast={isLast} onClick={() => setSelectedPage(index)}>
                    {index + 1}
                  </PageNumber>
                )
              })}
          </Row>
          <NavigateButton onClick={onClickNext}>
            <Arrow src={ArrowRight} />
          </NavigateButton>
        </PageNavigator>
      </History>
    </Container>
  )
}
