import React from 'react'
import { Price } from '@uniswap/sdk'
import { useContext } from 'react'
import { Repeat } from 'react-feather'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { StyledBalanceMaxMini } from './styleds'
import useBnbChain from 'hooks/useBnbChain'
import useZetaChain from 'hooks/useZetaChain'
import useBriseChain from 'hooks/useBriseChain'
import useOmniChain from 'hooks/useOmniChain'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const theme = useContext(ThemeContext)

  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const isBnbChain = useBnbChain()
  const isZetaChain = useZetaChain()
  const isBriseChain = useBriseChain()
  const isOmniChain = useOmniChain()

  const symbolA = isBnbChain && price?.quoteCurrency?.symbol == 'ETH' ? 'BNB' :  isZetaChain && price?.quoteCurrency?.symbol == 'ETH' ? 'ZETA' :  isBriseChain && price?.quoteCurrency?.symbol == 'ETH' ? 'BRISE' :  isOmniChain && price?.quoteCurrency?.symbol == 'ETH' ? 'OMNI' : price?.quoteCurrency?.symbol
  const symbolB = isBnbChain && price?.baseCurrency?.symbol == 'ETH' ? 'BNB' :  isZetaChain && price?.baseCurrency?.symbol == 'ETH' ? 'ZETA' : isBriseChain && price?.baseCurrency?.symbol == 'ETH' ? 'BRISE' : isOmniChain && price?.baseCurrency?.symbol == 'ETH' ? 'OMNI' : price?.baseCurrency?.symbol

  const label = showInverted ? `${symbolA} per ${symbolB}` : `${symbolB} per ${symbolA}`

  return (
    <Text
      fontWeight={500}
      fontSize={14}
      color={theme.text2}
      style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}
    >
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <Repeat size={14} />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
