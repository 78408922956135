import styled from 'styled-components'
import { ExternalLink } from 'theme'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1272px;
  margin-top: 64px;
  margin-bottom: 64px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: auto;
    flex-direction: column;
    margin-top: 32px;
  `};
`

export const AppItem = styled(ExternalLink)`
  :hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  transition: border-color 0.2s ease;
  padding: 22px;
  border: 1px solid #666;
  background: black;
  width: 392px;
  min-height: 266px;
  margin-right: 32px;
  margin-bottom: 32px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 0px;
    margin-bottom: 32px;
  `};
`

export const StyledLogo = styled.img<{ size?: string | undefined }>`
  height: 48px;
`
