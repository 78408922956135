import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { NETWORK_CHAIN_ID, NETWORK_URL } from '../../connectors'
import { getChainName, ETHERSCAN_PREFIXES, NATIVE_CURRENCY } from 'utils'
import { ethers } from 'ethers'
import { ChainId } from '@uniswap/sdk'

const ChainValidation = ({ children }: any) => {
  const { account, chainId, library } = useWeb3React()

  const findChainId = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const chain: any = urlParams.get('chain') || NETWORK_CHAIN_ID
    if (chain != NETWORK_CHAIN_ID) {
      const addChainParameter = {
        chainId: ethers.utils.hexValue(chain),
        chainName: getChainName(chain),
        rpcUrls: [NETWORK_URL[chain as ChainId]],
        nativeCurrency: NATIVE_CURRENCY[chain as ChainId],
        blockExplorerUrls: [ETHERSCAN_PREFIXES[chain as ChainId]]
      }
      await library.provider.request({
        id: 1,
        jsonrpc: '2.0',
        method: 'wallet_addEthereumChain',
        params: [addChainParameter]
      })
    }
  }

  useEffect(() => {
    if (chainId !== NETWORK_CHAIN_ID) {
      findChainId()
    }
  }, [chainId, account])

  return children
}

export default ChainValidation
