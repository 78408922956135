import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { ROUTER_ADDRESS } from '../constants'
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@uniswap/sdk'
import { TokenAddressMap } from '../state/lists/hooks'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}
//

export const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  7001: 'https://athens3.explorer.zetachain.com',
  8453: 'https://basescan.org',
  84531: 'https://goerli.basescan.org',
  59144: 'https://lineascan.build',
  59140: 'https://goerli.lineascan.build',
  5611: 'https://opbnbscan.com',
  204: 'https://mainnet.opbnbscan.com',
  324: 'https://explorer.zksync.io',
  42161: 'https://arbiscan.io',
  534351: 'https://sepolia-blockscout.scroll.io',
  32520: 'https://brisescan.com',
  165: 'https://testnet.explorer.omni.network',
  167007: 'https://explorer.jolnir.taiko.xyz',
  534352: 'https://scroll-mainnet.l2scan.co',
  169: 'https://pacific-explorer.manta.network'
}

export const NATIVE_CURRENCY: { [chainId in ChainId]: any } = {
  165: {
    name: 'OMNI',
    symbol: 'OMNI',
    decimals: 18
  },
  534351: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  169: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  534352: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  7001: {
    name: 'ZETA',
    symbol: 'ZETA',
    decimals: 18
  },
  8453: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  84531: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  59144: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  59140: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  5611: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18
  },
  204: {
    name: 'BNB',
    symbol: 'BNB',
    decimals: 18
  },
  324: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  42161: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  },
  32520: {
    name: 'BRISE',
    symbol: 'BRISE',
    decimals: 18
  },
  167007: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18
  }
}

export const CHAIN_NAME_FORMAT: { [chainId in ChainId]: string } = {
  7001: 'ZETA',
  8453: 'Base',
  84531: 'Base Testnet',
  59144: 'Linea',
  59140: 'Linea Testnet',
  5611: 'opBNB Testnet',
  204: 'opBNB',
  324: 'zksync',
  42161: 'arbitrum',
  534351: 'scroll sepolia',
  32520: 'brise',
  165: 'omni',
  167007: 'taiko',
  534352: 'scroll',
  169: 'manta'
}

export const CHAIN_NAME: { [chainId in ChainId]: string } = {
  7001: 'zeta',
  8453: 'base',
  84531: 'base',
  59144: 'linea',
  59140: 'linea',
  5611: 'opbnb',
  204: 'opbnb',
  324: 'zksync',
  42161: 'arbitrum',
  534351: 'scroll',
  32520: 'brise',
  165: 'omni',
  167007: 'taiko',
  534352: 'scroll',
  169: 'manta'
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
): string {
  const prefix = ETHERSCAN_PREFIXES[chainId]

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export const getChainName = ({ chainId }: { chainId: ChainId }) => {
  return CHAIN_NAME[chainId]
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(chainId: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[chainId || ChainId.BASE], IUniswapV2Router02ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}
