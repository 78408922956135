import LogoUquid from '../../assets/images/img-uquid.png'
import LogoRhinoFi from '../../assets/images/img-rhinofi.png'
import Degame from '../../assets/images/img-degame.png'
import Link3 from '../../assets/images/img-link3.png'
import QuestN from '../../assets/images/img-questn.png'
import DefiLlama from '../../assets/images/img-defillama.png'
import ZNSConnect from '../../assets/images/img-zns-connect.png'
import Dexscreener from '../../assets/images/img-dexscreener.png'
import DexTools from '../../assets/images/img-dextools.png'
import Nabox from '../../assets/images/img-nabox.png'
import GeckoTerminal from '../../assets/images/img-gecko-ternimal.png'
import MintFun from '../../assets/images/img-mint-fun.png'
import SpaceID from '../../assets/images/img-space-id.png'
import MagicSquare from '../../assets/images/img-magic-square.png'
import KenzoLab from '../../assets/images/img-kenzoLab.png'
import dAppBay from '../../assets/images/img-dAppBay.png'
import veSync from '../../assets/images/img-veSync.png'
import Cryptoverse from '../../assets/images/img-cryptoverse.png'
import dexcheck from '../../assets/images/img-dexcheck.png'
import Bitgert from '../../assets/images/img-bitgert.png'

export const DAPPS = [
  {
    id: 'bitgert',
    name: 'BITGERT',
    logo: Bitgert,
    desc: 'Bitgert is a rapidly expanding crypto project that boasts a gas fee-free blockchain, CEX, and a lot more!',
    link: 'https://bitgert.com/'
  },
  {
    id: 'uquid',
    name: 'Uquid',
    logo: LogoUquid,
    desc:
      'Uquid is the biggest Web 3.0 shopping platform, which provides digital, physical and NFT products by establishing the bridge between Defi and E-commerce.',
    link: 'https://shop.uquid.com/'
  },
  {
    id: 'rhinofi',
    name: 'Rhino.fi',
    logo: LogoRhinoFi,
    desc:
      'Seamlessly bridge between 8 chains using rhino.fi, The Layer 2 DeFi aggregator. Explore 500+ tokens across 8 chains. Bridge, swap, stake, farm all in one place. Unlock secure, self-custodial DeFi today.',
    link: 'https://app.rhino.fi/bridge?token=ETH&chainOut=LINEA'
  },
  {
    id: 'degame',
    name: 'Degame',
    logo: Degame,
    desc:
      'DeGame is the largest NFT games aggregator, listing over 4000 blockchain game projects and 1000+ tokens & chains since 2021. With partnerships with 50+ guilds and chains.',
    link: 'https://degame.com/en/community/140378?name=PixelSwap---Built-on-Multi-Chain'
  },
  {
    id: 'link3',
    name: 'Link3',
    logo: Link3,
    desc:
      'Link3 is a Web3 social network of verifiable identities to build and discover new communities. Web3 Social Dapps. Web3 Social Media Dapps. BNB Chain, Ethereum, EVM, Polygon.',
    link: 'https://link3.to/pixelswapfi'
  },
  {
    id: 'questn',
    name: 'QuestN',
    logo: QuestN,
    desc: 'QuestN is a do-to-earn platform where projects and protocols fund a liquidity pool that is quest-based',
    link: 'https://app.questn.com/PixelSwap'
  },
  {
    id: 'defillama',
    name: 'DefiLlama',
    logo: DefiLlama,
    desc:
      'DeFiLlama is the largest DeFi data aggregator at the moment. It provides up-to-date information about all known Level 1 and Level 2 (L1 and L2) blockchains.',
    link: 'https://defillama.com/protocol/pixelswap'
  },
  {
    id: 'zns_connect',
    name: 'ZNS Connect',
    logo: ZNSConnect,
    desc:
      'ZNS Connect built on the Zetachain blockchain. It provides a user-friendly and memorable way to identify and interact with participants within the web3 ecosystem.',
    link: 'https://znsconnect.io/'
  },
  {
    id: 'dexscreener',
    name: 'Dexscreener',
    logo: Dexscreener,
    desc:
      'DEX Screener is an online tool that allows users to filter through a variety of assets across decentralized exchanges to find the best investment opportunities.',
    link: 'https://dexscreener.com/'
  },
  {
    id: 'dextools',
    name: 'DexTools',
    logo: DexTools,
    desc:
      'DEXTools is a DApp for decentralized finance (DeFi) that offers one of the most complete sets of trading tools possible for decentralized exchange (DEX) users.',
    link: 'https://info.dextools.io/'
  },
  {
    id: 'nabox',
    name: 'Nabox',
    logo: Nabox,
    desc:
      'Nabox was introduced as a simple wallet for the NULS ecosystem to send and receive NULS tokens. As DeFi started becoming more popular, the vision of NULS started becoming more apparent.',
    link: 'https://nabox.io/'
  },
  {
    id: 'geckoterminal',
    name: 'Gecko Terminal',
    logo: GeckoTerminal,
    desc:
      'GeckoTerminal is a real-time tracking tool for tokens trading on decentralized crypto exchanges (DEXs), where users can easily find information on prices, trading volume, liquidity and more.',
    link: 'https://www.geckoterminal.com/'
  },
  {
    id: 'mintfun',
    name: 'Mint.fun',
    logo: MintFun,
    desc:
      'Mint.fun is an NFT platform developed by Context, enabling users to mint NFTs and sell them on various NFT Marketplaces across the Ethereum, Optimism, and Zora blockchains.',
    link: 'https://mint.fun/base/0x5bC683a3Afd082784eBc11E05ec568C6A4ACDF89'
  },
  {
    id: 'spaceid',
    name: 'Space ID',
    logo: SpaceID,
    desc:
      'Space ID (ID) is a name service network for different blockchains. Users can have their own web3 domain registered on different blockchains, such as BNB Chain and Arbitrum.',
    link: 'https://space.id/'
  },
  {
    id: 'magicsquare',
    name: 'Magic Square',
    logo: MagicSquare,
    desc: 'Magic Square is a discovery and engagement platform for the world of Web3, blockchain, and crypto apps.',
    link: 'https://magic.store/app/pixelswap'
  },
  {
    id: 'kenzolab',
    name: 'KenzoLab',
    logo: KenzoLab,
    desc:
      'Kenzo Labs is a marketing agency and venture capital focused on developing early stage projects with good potential and profitability, we aim to introduce good and potential projects our followers around the world.',
    link: 'https://kenzolabs.com/'
  },
  {
    id: 'dappbay',
    name: 'dAppBay',
    logo: dAppBay,
    desc:
      'DappBay is a Web3 dApp Store for users to safely explore and use dApps on BNB Chain. Users can discover new dApps, explore dApp rankings, analytics, campaigns, airdrops and scan risky contracts in real-time with DappBay.',
    link: 'https://dappbay.bnbchain.org/detail/pixelswap'
  },
  {
    id: 'vesync',
    name: 'veSync',
    logo: veSync,
    desc:
      'veSync is a community-driven, ve(3,3) decentralized exchange (DEX) on the zkSync Era, focusing on the centralization of DeFi liquidity.',
    link: 'https://app.vesync.finance/swap/'
  },
  {
    id: 'cryptoverse',
    name: 'Cryptoverse',
    logo: Cryptoverse,
    desc:
      'Crypto Verse for everyone, everywhere - Binance Feed / Live Creator Official Partner - OKX, AMA, OKB, BTC, TRON.',
    link: 'https://bio.link/cryptoverseco/'
  },
  {
    id: 'dexcheck',
    name: 'DexCheck',
    logo: dexcheck,
    desc:
      'DexCheck is an AI-enhanced analytics platform for cryptocurrencies and NFT markets. Committed to delivering actionable insights and advanced data analysis, DexCheck empowers investors to navigate the complex world of digital assets with confidence.',
    link: 'https://dexcheck.ai/app'
  }
]
