import { ChainId, JSBI, Percent, Token, WETH } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected, walletconnect, walletlink } from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const ROUTER_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.BASE]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.BASE_GOERLI]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.LINEA]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.LINEA_GOERLI]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.OP_BNB_TESTNET]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.OP_BNB]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.ZKSYNC]: '0x6F5baC3bA9b918C3194b4Ff2233BB2b708312b8E',
  [ChainId.ARBITRUM]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.ZETA_TESTNET]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.SCROLL_TESTNET]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.BRISE]: '0xAE85dd0A742Bc36C8ADf0931F9e2365Ab2712787',
  [ChainId.OMNI_TESTNET]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.TAIKO_TESTNET]: '0xE4f7776c753aF46D2aa23e3348d17548C86DC47D',
  [ChainId.SCROLL]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.MANTA]: '0x57B0c1ab1C9396950f149456C30Ea34CBde7b973'
}

export const PAIR_NAME = 'Pixelswap V2'

export { PRELOADED_PROPOSALS } from './proposals'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

const SWAP_TOKEN_ADDRESS = '0x92395093468F8d15594726933DaB7847a2f48b97'

export const USDC: { [chainId in ChainId]?: Token | any } = {
  [ChainId.BASE]: undefined,
  [ChainId.BASE_GOERLI]: undefined,
  [ChainId.LINEA]: undefined,
  [ChainId.LINEA_GOERLI]: new Token(
    ChainId.LINEA_GOERLI,
    '0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068',
    6,
    'USDC',
    'USD//C'
  ),
  [ChainId.OP_BNB_TESTNET]: new Token(
    ChainId.OP_BNB_TESTNET,
    '0xd3E67b55f8A6cdC77f69C39903cb1B3C3CF6a5E4',
    6,
    'USDC',
    'USD//C'
  )
}

export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.TAIKO_TESTNET]: new Token(
    ChainId.TAIKO_TESTNET,
    '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F',
    18,
    'Pixel',
    'Pixelswap'
  ),
  [ChainId.OMNI_TESTNET]: new Token(
    ChainId.OMNI_TESTNET,
    '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
    18,
    'Pixel',
    'Pixelswap'
  ),
  [ChainId.SCROLL_TESTNET]: new Token(
    ChainId.SCROLL_TESTNET,
    '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F',
    18,
    'Pixel',
    'Pixelswap'
  ),
  [ChainId.BASE]: new Token(ChainId.BASE, '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F', 18, 'Pixel', 'Pixelswap'),
  [ChainId.BASE_GOERLI]: new Token(
    ChainId.BASE_GOERLI,
    '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F',
    18,
    'Pixel',
    'Pixelswap'
  ),
  [ChainId.LINEA_GOERLI]: new Token(
    ChainId.LINEA_GOERLI,
    '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
    18,
    'Pixel',
    'Pixelswap'
  ),
  [ChainId.LINEA]: new Token(ChainId.LINEA, '0xAE85dd0A742Bc36C8ADf0931F9e2365Ab2712787', 18, 'Pixel', 'Pixelswap'),
  [ChainId.OP_BNB_TESTNET]: new Token(
    ChainId.OP_BNB_TESTNET,
    '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
    18,
    'Pixel',
    'Pixelswap'
  ),
  [ChainId.OP_BNB]: new Token(ChainId.OP_BNB, '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F', 18, 'Pixel', 'Pixelswap'),
  [ChainId.ZKSYNC]: new Token(ChainId.ZKSYNC, '0xEF4f81bfE90481EE18D5aA8D79D7203b2914213e', 18, 'Pixel', 'Pixelswap'),
  [ChainId.ARBITRUM]: new Token(
    ChainId.ARBITRUM,
    '0x2BBE8e5Fe1f7347f542f6ddeD2F19f7Db977177d',
    18,
    'Pixel',
    'Pixelswap'
  ),
  [ChainId.ZETA_TESTNET]: new Token(
    ChainId.ZETA_TESTNET,
    '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F',
    18,
    'Pixel',
    'Pixelswap'
  ),
  [ChainId.SCROLL]: new Token(ChainId.SCROLL, '0xAE85dd0A742Bc36C8ADf0931F9e2365Ab2712787', 18, 'Pixel', 'Pixelswap'),
  [ChainId.BRISE]: new Token(ChainId.BRISE, '0x5a48e04d5b994C7f9dD0dA1E74725078648f5009', 18, 'Pixel', 'Pixelswap'),
  [ChainId.MANTA]: new Token(ChainId.MANTA, '0x3E5f61A603AE9671d01ef54Ab0b2BB1A3a6aB2F7', 18, 'Pixel', 'Pixelswap')
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [SWAP_TOKEN_ADDRESS]: 'Pixel'
  // [GOVERNANCE_ADDRESS]: 'Governance',
  // [TIMELOCK_ADDRESS]: 'Timelock'
}

// TODO: specify merkle distributor for mainnet
// export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
//   1: '0x090D4613473dEE047c3f2706764f49E0821D256e'
// }

const WETH_ONLY: ChainTokenList = {
  [ChainId.BASE]: [WETH[ChainId.BASE]],
  [ChainId.BASE_GOERLI]: [WETH[ChainId.BASE_GOERLI]],
  [ChainId.LINEA]: [WETH[ChainId.LINEA]],
  [ChainId.LINEA_GOERLI]: [WETH[ChainId.LINEA_GOERLI]],
  [ChainId.OP_BNB_TESTNET]: [WETH[ChainId.OP_BNB_TESTNET]],
  [ChainId.OP_BNB]: [WETH[ChainId.OP_BNB]],
  [ChainId.ZKSYNC]: [WETH[ChainId.ZKSYNC]],
  [ChainId.ARBITRUM]: [WETH[ChainId.ARBITRUM]],
  [ChainId.ZETA_TESTNET]: [WETH[ChainId.ZETA_TESTNET]],
  [ChainId.SCROLL_TESTNET]: [WETH[ChainId.SCROLL_TESTNET]],
  [ChainId.BRISE]: [WETH[ChainId.BRISE]],
  [ChainId.OMNI_TESTNET]: [WETH[ChainId.OMNI_TESTNET]],
  [ChainId.TAIKO_TESTNET]: [WETH[ChainId.TAIKO_TESTNET]],
  [ChainId.SCROLL]: [WETH[ChainId.SCROLL]],
  [ChainId.MANTA]: [WETH[ChainId.MANTA]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC]
}

export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.MAINNET]: {
  //   [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  // }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY
  // [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  // [ChainId.MAINNET]: [
  //   [
  //     new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
  //     new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
  //   ],
  //   [USDC, USDT],
  //   [DAI, USDT]
  // ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C'
]
