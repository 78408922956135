import styled from 'styled-components'

export const Container = styled.div`
  width: 964px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};
`

export const LotaltyInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 32px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};
`

export const LotaltyInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 12px;
  background: var(--Grey-1, #252525);
  margin-right: 32px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin-bottom: 32px;
    margin-right: 0px;
  `};
`

export const LotaltyInfoLogo = styled.img`
  height: 16px;
`

export const LoyaltySummaryItem = styled.div`
  padding: 22px;
  width: 400px;
  margin-right: 32px;
  border: 1px solid var(--white-20, #666);
  background: black;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 0px;
    margin-bottom: 32px;
  `};
`

export const LoyaltySummaryContainer = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin-top: 0px;
  `};
`

export const History = styled.div`
  margin-bottom: 64px;
  padding: 32px;
  width: 100%;
  border: 1px solid var(--white-20, #666);
  background: #000;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 8px;
  `};
`

export const HistoryLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 14px;
    justify-content: space-between;
  `};
`

export const HistoryItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #2b2b2b;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 14px;
    padding-bottom: 10px;
    justify-content: space-between;
  `};
`

export const HistoryItem = styled.div<{ isDate?: boolean }>`
  width: ${({ isDate }) => (isDate ? '20%' : '40%')};
  margin-right: 32px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-right: 8px;
    width: 33%;
`};
`

export const PageNavigator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`

export const NavigateButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  :hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.primary1};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 20px;
    height: 20px;
  `};
`

export const PageNumber = styled.div<{ selected: boolean; isLast: boolean }>`
  cursor: pointer;
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${({ selected }) => (selected ? 'white' : '#666')};
  margin-left: 16px;
  margin-right: ${({ isLast }) => (isLast ? '16px' : '0px')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
`};
`

export const Arrow = styled.img`
  height: 16px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 12px;
    height: 12px;
  `};
`
