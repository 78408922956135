// import { namehash } from 'ethers/lib/utils'
import { useEffect, useState } from 'react'
// import { useSingleCallResult } from '../state/multicall/hooks'
// import { isAddress } from '../utils'
// import isZero from '../utils/isZero'
// import { useENSRegistrarContract, useENSResolverContract } from './useContract'
// import useDebounce from './useDebounce'
// @ts-ignore
import SID, { getSidAddress } from '@siddomains/sidjs'
import { useActiveWeb3React } from 'hooks'
import { ChainId } from '@uniswap/sdk'

const getSID = async (provider: any, chainId: any, address: string) => {
  if (address && chainId == ChainId.ARBITRUM) {
    const sid: any = new SID({ provider, sidAddress: getSidAddress(chainId) })
    const nameSpaceID = await sid.getName(address)
    return nameSpaceID.name
  } else {
    return ''
  }
}
/**
 * Does a reverse lookup for an address to find its ENS name.
 * Note this is not the same as looking up an ENS name to find an address.
 */
export default function useENSName(address?: string): { ENSName: string | null; loading: boolean } {
  // const debouncedAddress = useDebounce(address, 200)
  const { library, chainId } = useActiveWeb3React()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  useEffect(() => {
    if (address && !loading && library?.provider) {
      const getName = async () => {
        setLoading(true)
        const nameSpace: any = await getSID(library?.provider, chainId, address || '')
        setLoading(false)
        setName(nameSpace ?? '')
      }
      getName()
    }
  }, [address, chainId])
  // const ensNodeArgument = useMemo(() => {
  //   if (!debouncedAddress || !isAddress(debouncedAddress)) return [undefined]
  //   try {
  //     return debouncedAddress ? [namehash(`${debouncedAddress.toLowerCase().substr(2)}.addr.reverse`)] : [undefined]
  //   } catch (error) {
  //     return [undefined]
  //   }
  // }, [debouncedAddress])
  // const registrarContract = useENSRegistrarContract(false)
  // const resolverAddress = useSingleCallResult(registrarContract, 'resolver', ensNodeArgument)
  // const resolverAddressResult = resolverAddress.result?.[0]
  // const resolverContract = useENSResolverContract(
  //   resolverAddressResult && !isZero(resolverAddressResult) ? resolverAddressResult : undefined,
  //   false
  // )

  // const name = useSingleCallResult(resolverContract, 'name', ensNodeArgument)

  // const changed = debouncedAddress !== address
  return {
    ENSName: name,
    loading: loading
  }
}
