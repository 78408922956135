import React, { useContext } from 'react'
import { Text } from 'rebass'

import { ThemeContext } from 'styled-components'
import { AppItem, Row, StyledLogo } from './styleds'
import { DAPPS } from './constants'
import { ExternalLink } from 'theme'

export default function Dapps() {
  const theme = useContext(ThemeContext)
  return (
    <>
      <Text fontWeight={400} fontSize={32} color={theme.primary1}>
        DApps
      </Text>
      <Text fontWeight={400} fontSize={16} marginTop={32} color={theme.white} textAlign="center">
        Synthesis of DApps are strategic partners of PixelSwap and are developed on multi-chains
      </Text>
      <Row>
        {DAPPS.map(dapp => {
          return (
            <AppItem key={dapp.id} href={dapp.link} hoverUnderlineNone>
              <StyledLogo src={dapp.logo} />
              <Text fontWeight={400} fontSize={20} marginTop={12} color={theme.white}>
                {dapp.name}
              </Text>
              <Text fontWeight={400} fontSize={12} marginTop={12} color="#C2C2C2">
                {dapp.desc}
              </Text>
              <ExternalLink href={dapp.link}>
                <Text fontWeight={400} fontSize={12} marginTop={12} color={theme.primary1}>
                  Open app <span style={{ fontSize: '16px' }}>↗</span>
                </Text>
              </ExternalLink>
            </AppItem>
          )
        })}
      </Row>
    </>
  )
}
