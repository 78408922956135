import { ChainId, Currency, ETHER, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import EthereumLogo from '../../assets/images/ethereum-logo.png'
import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import { UNI } from '../../constants'
import { useWeb3React } from '@web3-react/core'
import { NETWORK_CHAIN_ID } from '../../connectors'
import useBnbChain from 'hooks/useBnbChain'
import useZetaChain from 'hooks/useZetaChain'
import useBriseChain from 'hooks/useBriseChain'
import useOmniChain from 'hooks/useOmniChain'

export const getTokenLogoURL = (address: string, chainId: any) => {
  if (address == UNI[(chainId as ChainId) || NETWORK_CHAIN_ID].address) {
    return 'https://i.imgur.com/CqS9AGL.png'
  }
  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`
}

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: black;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const { chainId } = useWeb3React()
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)
  const isBnbChain = useBnbChain()
  const isZetaChain = useZetaChain()
  const isBriseChain = useBriseChain()
  const isOmniChain = useOmniChain()

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address, chainId)]
      }
      return [getTokenLogoURL(currency.address, chainId)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return (
      <StyledEthereumLogo
        src={isBnbChain ? 'https://i.imgur.com/vkk2CqQ.png' : isZetaChain ? 'https://i.imgur.com/r6eCu8C.png' : isBriseChain ? 'https://i.imgur.com/byDK2jw.png' : isOmniChain ? 'https://i.imgur.com/F1AJIk9.png' : EthereumLogo}
        size={size}
        style={style}
      />
    )
  }

  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
