import { Currency, CurrencyAmount, Fraction, Percent } from '@uniswap/sdk'
import React from 'react'
import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import { RowBetween, RowFixed } from '../../components/Row'
import CurrencyLogo from '../../components/CurrencyLogo'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import useBnbChain from 'hooks/useBnbChain'
import useZetaChain from 'hooks/useZetaChain'
import useBriseChain from 'hooks/useBriseChain'
import useOmniChain from 'hooks/useOmniChain'

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  const isBnbChain = useBnbChain()
  const isZetaChain = useZetaChain()
  const isBriseChain = useBriseChain()
  const isOmniChain = useOmniChain()

  const symbolA =
    isBnbChain && currencies[Field.CURRENCY_A]?.symbol == 'ETH' ? 'BNB' : isZetaChain && currencies[Field.CURRENCY_A]?.symbol == 'ETH' ? 'ZETA' : isBriseChain && currencies[Field.CURRENCY_A]?.symbol == 'ETH' ? 'BRISE' : isOmniChain && currencies[Field.CURRENCY_A]?.symbol == 'ETH' ? 'OMNI' : currencies[Field.CURRENCY_A]?.symbol
  const symbolB =
    isBnbChain && currencies[Field.CURRENCY_B]?.symbol == 'ETH' ? 'BNB' : isZetaChain && currencies[Field.CURRENCY_B]?.symbol == 'ETH' ? 'ZETA' : isBriseChain && currencies[Field.CURRENCY_B]?.symbol == 'ETH' ? 'BRISE' : isOmniChain && currencies[Field.CURRENCY_B]?.symbol == 'ETH' ? 'OMNI' : currencies[Field.CURRENCY_B]?.symbol
  return (
    <>
      <RowBetween>
        <TYPE.body>{symbolA} Deposited</TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body>{symbolB} Deposited</TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Rates</TYPE.body>
        <TYPE.body>{`1 ${symbolA} = ${price?.toSignificant(4)} ${symbolB}`}</TYPE.body>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <TYPE.body>{`1 ${symbolB} = ${price?.invert().toSignificant(4)} ${symbolA}`}</TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Share of Pool:</TYPE.body>
        <TYPE.body>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</TYPE.body>
      </RowBetween>
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? 'Create Pool & Supply' : 'Confirm Supply'}
        </Text>
      </ButtonPrimary>
    </>
  )
}
