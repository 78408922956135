import { ChainId, TokenAmount } from '@uniswap/sdk'
import React, { useState, useRef } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { ethers } from 'ethers'
import { NETWORK_URL } from '../../connectors'
import styled from 'styled-components'

import Logo from '../../assets/images/img-logo.png'
import LogoDark from '../../assets/images/img-logo.png'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances, useAggregateUniBalance } from '../../state/wallet/hooks'
import { CardNoise } from '../earn/styled'
import { CountUp } from 'use-count-up'
import { TYPE } from '../../theme'

// import { Moon, Sun } from 'react-feather'
import Menu from '../Menu'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { useToggleSelfClaimModal, useShowClaimPopup, useModalOpen, useToggleModal } from '../../state/application/hooks'

import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import usePrevious from '../../hooks/usePrevious'
import { ETHERSCAN_PREFIXES, NATIVE_CURRENCY, CHAIN_NAME_FORMAT } from 'utils' //getChainName,
// import { UNI } from '../../constants'
import { YellowCard } from 'components/Card'
import { ApplicationModal } from 'state/application/actions'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import opBNBLogo from '../../assets/images/ic-opbnb.png'
import lineaLogo from '../../assets/images/ic-linea.png'
import baseLogo from '../../assets/images/ic-base.png'
import arbLogo from '../../assets/images/ic-arb.png'
import zksyncLogo from '../../assets/images/ic_zksync.png'
import zetaLogo from '../../assets/images/ic-zetachain.png'
import scrollLogo from '../../assets/images/ic-scroll.png'
import briseLogo from '../../assets/images/ic-brise.png'
import omniLogo from '../../assets/images/ic-omni.png'
import taikoLogo from '../../assets/images/ic-taiko.png'
import mantaLogo from '../../assets/images/ic-manta.png'

import useBnbChain from 'hooks/useBnbChain'
import useZetaChain from 'hooks/useZetaChain'
import useBriseChain from 'hooks/useBriseChain'
import useOmniChain from 'hooks/useOmniChain'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 8px;
  font-weight: 500;
  background-color: #2b2b2b;
  text-decoration: underline;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgba(243, 132, 30, 0.05);
  padding: 0px;
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`

const NetworkCard = styled(YellowCard)`
  width: auto;
  padding: 8px 12px;
  color: ${({ theme }) => theme.primary1};
  font-weight: 700;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: #121212;
  border: 1px solid ${({ theme }) => theme.primary1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 0px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 6rem;
  right: 24rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: 24rem;
    right: 2rem;
    left: 2rem;
  `};
`

const MenuItem = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  background-color: transparent;
  text-align: left;
  border: none;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  :hover {
    scale: 1.2;
  }
  margin-top: 6px;
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 10px;
  `};
`

// const StyledExternalLink = styled(ExternalLink).attrs({
//   activeClassName
// }) <{ isActive?: boolean }>`
//   ${({ theme }) => theme.flexRowNoWrap}
//   align-items: left;
//   border-radius: 3rem;
//   outline: none;
//   cursor: pointer;
//   text-decoration: none;
//   color: ${({ theme }) => theme.text2};
//   font-size: 1rem;
//   width: fit-content;
//   margin: 0 12px;
//   font-weight: 500;

//   &.${activeClassName} {
//     border-radius: 12px;
//     font-weight: 600;
//     color: ${({ theme }) => theme.text1};
//   }

//   :hover,
//   :focus {
//     color: ${({ theme }) => darken(0.1, theme.text1)};
//   }

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//       display: none;
// `}
// `

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

export const ChainLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: -4px;
  margin-right: 8px;
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.OP_BNB]: 'opBNB',
  [ChainId.BASE]: 'BASE',
  [ChainId.LINEA]: 'LINEA',
  [ChainId.ZKSYNC]: 'ZKSYNC',
  [ChainId.ARBITRUM]: 'ARBITRUM',
  [ChainId.ZETA_TESTNET]: 'ZETACHAIN',
  [ChainId.SCROLL_TESTNET]: 'Scroll Sepolia',
  [ChainId.BRISE]: 'BRISE',
  [ChainId.OMNI_TESTNET]: 'OMNI TESTNET',
  [ChainId.TAIKO_TESTNET]: 'TAIKO TESTNET',
  [ChainId.SCROLL]: 'Scroll',
  [ChainId.MANTA]: 'Manta'
  // [ChainId.BASE_GOERLI]: 'BASE Goerli',
  // [ChainId.LINEA]: 'LINEA',
  // [ChainId.LINEA_GOERLI]: 'LINEA Goerli',
  // [ChainId.OP_BNB_TESTNET]: 'opBNB Testnet'
}

const CHAIN_SUPPORT = [
  {
    chainId: ChainId.SCROLL,
    logo: scrollLogo
  },
  {
    chainId: ChainId.MANTA,
    logo: mantaLogo
  },
  {
    chainId: ChainId.BASE,
    logo: baseLogo
  },
  {
    chainId: ChainId.LINEA,
    logo: lineaLogo
  },
  {
    chainId: ChainId.ZKSYNC,
    logo: zksyncLogo
  },
  {
    chainId: ChainId.ARBITRUM,
    logo: arbLogo
  },
  {
    chainId: ChainId.ZETA_TESTNET,
    logo: zetaLogo
  },

  {
    chainId: ChainId.BRISE,
    logo: briseLogo
  },
  {
    chainId: ChainId.OMNI_TESTNET,
    logo: omniLogo
  },
  {
    chainId: ChainId.TAIKO_TESTNET,
    logo: taikoLogo
  },
  {
    chainId: ChainId.OP_BNB,
    logo: opBNBLogo
  }
  // {
  //   chainId: ChainId.OP_BNB_TESTNET,
  //   logo: opBNBLogo
  // }
]

export default function Header() {
  const { account, chainId, library } = useActiveWeb3React()
  const { t } = useTranslation()
  const isBnbChain = useBnbChain()
  const isZetaChain = useZetaChain()
  const isBriseChain = useBriseChain()
  const isOmniChain = useOmniChain()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

  // const [isDark] = useDarkModeManager()
  const [darkMode] = useDarkModeManager()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()
  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.NETWORK_LIST)
  const toggle = useToggleModal(ApplicationModal.NETWORK_LIST)
  useOnClickOutside(node, open ? toggle : undefined)

  // const pairAnalyticsUrl = useMemo(() => {
  //   if (chainId) {
  //     return ''
  //   }
  //   return `https://dexscreener.com/${getChainName({ chainId: chainId ? chainId : ChainId.MAINNET })}/${
  //     chainId ? UNI[chainId] : undefined
  //   }`
  // }, [chainId])

  const switchChain = async (chain: any) => {
    if (library && library.provider.request) {
      try {
        await library?.provider?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: ethers.utils.hexValue(chain) }]
        })
      } catch (switchError) {
        // 4902 error code indicates the chain is missing on the wallet
        if (((switchError as any).code as number) === 4902) {
          try {
            const addChainParameter = {
              chainId: ethers.utils.hexValue(chain),
              chainName: CHAIN_NAME_FORMAT[chain as ChainId],
              rpcUrls: [NETWORK_URL[chain as ChainId]],
              nativeCurrency: NATIVE_CURRENCY[chain as ChainId],
              blockExplorerUrls: [ETHERSCAN_PREFIXES[chain as ChainId]]
            }
            return await library.provider.request({
              method: 'wallet_addEthereumChain',
              params: [addChainParameter]
            })
          } catch (error) {
            console.error(error)
          }
        }
      }
    }
  }

  return (
    <HeaderFrame ref={node as any}>
      <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      <HeaderRow>
        <Title href=".">
          <UniIcon>
            <img width={'32px'} src={darkMode ? LogoDark : Logo} alt="logo" />
          </UniIcon>
        </Title>
        <HeaderLinks>
          <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
            {t('Swap')}
          </StyledNavLink>
          <StyledNavLink
            id={`pool-nav-link`}
            to={'/pool'}
            isActive={(match, { pathname }) =>
              Boolean(match) ||
              pathname.startsWith('/add') ||
              pathname.startsWith('/remove') ||
              pathname.startsWith('/create') ||
              pathname.startsWith('/find')
            }
          >
            {t('Pool')}
          </StyledNavLink>
          <StyledNavLink id={`stake-nav-link`} to={'/farm'}>
            Farm
          </StyledNavLink>
          <StyledNavLink id={`stake-nav-link`} to={'/dapps'}>
            DApps
          </StyledNavLink>
          {/* <StyledNavLink id={`stake-nav-link`} to={'/referrals'}>
            Referrals
          </StyledNavLink>
          <StyledNavLink id={`stake-nav-link`} to={'/loyalty'}>
            Loyalty
          </StyledNavLink> */}
        </HeaderLinks>
      </HeaderRow>
      <HeaderControls>
        <HeaderElement>
          {/* <HideSmall> */}
          {chainId && NETWORK_LABELS[chainId] && (
            <NetworkButton onClick={toggle}>
              <NetworkCard title={NETWORK_LABELS[chainId]}>{NETWORK_LABELS[chainId]}</NetworkCard>
            </NetworkButton>
          )}
          {/* </HideSmall> */}
          {availableClaim && !showClaimPopup && (
            <UNIWrapper onClick={toggleClaimModal}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming PIXEL</Dots> : 'Claim PIXEL'}
                </TYPE.white>
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          {!availableClaim && aggregateBalance && (
            <UNIWrapper onClick={() => setShowUniBalanceModal(true)}>
              <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                {account && (
                  <HideSmall>
                    <TYPE.white
                      style={{
                        paddingRight: '.4rem'
                      }}
                    >
                      <CountUp
                        key={countUpValue}
                        isCounting
                        start={parseFloat(countUpValuePrevious)}
                        end={parseFloat(countUpValue)}
                        thousandsSeparator={','}
                        duration={1}
                      />
                    </TYPE.white>
                  </HideSmall>
                )}
                $PIXEL
              </UNIAmount>
              <CardNoise />
            </UNIWrapper>
          )}
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
            {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                {userEthBalance?.toSignificant(4)}{' '}
                {isBnbChain ? 'BNB' : isZetaChain ? 'ZETA' : isBriseChain ? 'BRISE' : isOmniChain ? 'OMNI' : 'ETH'}
              </BalanceText>
            ) : null}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
        <HideSmall>
          <HeaderElementWrap>
            {/* <StyledMenuButton onClick={() => toggleDarkMode()}>
            {darkMode ? <Moon size={20} /> : <Sun size={20} />}
          </StyledMenuButton> */}
            <Menu />
          </HeaderElementWrap>
        </HideSmall>
      </HeaderControls>
      {open && (
        <MenuFlyout>
          {CHAIN_SUPPORT.map(chain => {
            return (
              <MenuItem
                key={chain.chainId}
                onClick={() => {
                  toggle()
                  switchChain(chain.chainId)
                }}
              >
                <ChainLogo src={chain.logo} />
                {NETWORK_LABELS[chain.chainId]}
              </MenuItem>
            )
          })}
        </MenuFlyout>
      )}
    </HeaderFrame>
  )
}
