import React, { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'

import { ThemeContext } from 'styled-components'
import {
  Container,
  ReferralLinkContainer,
  ReferralLink,
  CopyButton,
  CopyLogo,
  ReferralSummaryContainer,
  ReferralSummaryItem,
  Tier,
  TiersContainer,
  Leaderboard,
  LeaderboardLabelRow,
  LeaderboardItem,
  LeaderboardItemRow,
  PageNavigator,
  NavigateButton,
  PageNumber,
  Arrow
} from './styleds'
import Copy from '../../assets/images/ic-copy.svg'
import ArrowLeft from '../../assets/images/ic-pointer-left.svg'
import ArrowRight from '../../assets/images/ic-pointer-right.svg'
import { shortenAddress } from 'utils'
import Row from 'components/Row'

interface LeaderBoardByTier {
  [index: number]: { id: number; walletAddress: string; totalReferrals: number; totalFee: number }[]
}

const REFERRAL_LINK = 'https://pixelswap.xyz/referral=1234'
const TIERS = [
  {
    id: 1,
    title: 'Tier 1'
  },
  {
    id: 2,
    title: 'Tier 2'
  },
  {
    id: 3,
    title: 'Tier 3'
  }
]

const LeaderboardLabels = ['Top', 'Wallet', 'Total referrals', 'Total fee']

export default function Referrals() {
  const theme = useContext(ThemeContext)
  const [selectedTier, setSelectedTier] = useState(1)
  const [selectedPage, setSelectedPage] = useState(0)

  const referralSummary = useMemo(() => {
    return [
      {
        title: 'Your Tiers',
        value: 3
      },
      {
        title: 'Total referrals',
        value: '30 users'
      },
      {
        title: 'xPixel Earned',
        value: '$1.000,00'
      }
    ]
  }, [])

  const leaderboard: LeaderBoardByTier = useMemo(() => {
    return {
      1: Array(30)
        .fill(null)
        .map((_, index) => ({
          id: index,
          walletAddress: '0x1Bed701E49d83DfCC637240441CE11DC3B411021',
          totalReferrals: 300,
          totalFee: 2000
        })),
      2: Array(30)
        .fill(null)
        .map((_, index) => ({
          id: index,
          walletAddress: '0x1Bed701E49d83DfCC637240441CE11DC3B411021',
          totalReferrals: 400,
          totalFee: 3000
        })),
      3: Array(20)
        .fill(null)
        .map((_, index) => ({
          id: index,
          walletAddress: '0x1Bed701E49d83DfCC637240441CE11DC3B411021',
          totalReferrals: 500,
          totalFee: 4000
        }))
    }
  }, [])

  const leaderboardDisplayData = useMemo(() => {
    const currentLeaderboardByTier = leaderboard?.[selectedTier] || []
    const page = [...currentLeaderboardByTier].splice(selectedPage * 10, 10)

    return {
      page,
      pageCount: Math.ceil(currentLeaderboardByTier.length / 10)
    }
  }, [leaderboard, selectedPage, selectedTier])

  const onClickCopy = () => {
    navigator.clipboard
      .writeText(REFERRAL_LINK)
      .then(() => {
        console.log('Referral link copied to clipboard')
      })
      .catch(error => {
        console.error('Error copying referral link to clipboard:', error)
      })
  }

  const onClickPrevious = () => {
    if (selectedPage === 0) {
      return
    }
    setSelectedPage(prev => --prev)
  }

  const onClickNext = () => {
    if (selectedPage === leaderboardDisplayData.pageCount - 1) {
      return
    }
    setSelectedPage(prev => ++prev)
  }

  return (
    <Container>
      <Text fontWeight={400} fontSize={[24, 24, 32]} color={theme.primary1}>
        Referrals Program
      </Text>
      <ReferralLinkContainer>
        <Text fontWeight={400} fontSize={[14, 14, 16]} color={theme.white} marginBottom={[16, 16, 0]}>
          Your referral link:
        </Text>
        <ReferralLink
          fontWeight={400}
          fontSize={[12, 12, 14]}
          color={theme.white}
          marginX={[0, 0, 2]}
          marginBottom={[16, 16, 0]}
        >
          {REFERRAL_LINK}
        </ReferralLink>
        <CopyButton width="auto" height={[26, 26, 28]} onClick={onClickCopy}>
          <CopyLogo src={Copy} />
          Copy
        </CopyButton>
      </ReferralLinkContainer>
      <Text fontWeight={400} fontSize={[14, 14, 16]} marginBottom={16} color="#C2C2C2" textAlign="center">
        Get fee discounts and earn rebates through the PIXEL referral program.
      </Text>
      <Text fontWeight={400} fontSize={[14, 14, 16]} color="#C2C2C2" textAlign="center">
        For more information, please read the referral program details.
      </Text>
      <ReferralSummaryContainer>
        {referralSummary.map((summary, index) => {
          return (
            <ReferralSummaryItem key={index}>
              <Text fontWeight={400} fontSize={16} marginBottom={16} color="white">
                {summary.title}
              </Text>
              <Text fontWeight={400} fontSize={32} color="white">
                {summary.value}
              </Text>
            </ReferralSummaryItem>
          )
        })}
      </ReferralSummaryContainer>
      <Text fontWeight={400} fontSize={32} color="#C2C2C2" marginTop={[16, 16, 64]}>
        Leaderboard
      </Text>
      <TiersContainer>
        {TIERS.map((tier, index) => {
          const selected = selectedTier === tier.id
          const isLast = index === TIERS.length - 1

          const onSelect = () => {
            setSelectedTier(tier.id)
            setSelectedPage(0)
          }

          return (
            <Tier selected={selected} key={tier.id} isLast={isLast} onClick={onSelect}>
              {tier.title}
            </Tier>
          )
        })}
      </TiersContainer>
      <Leaderboard>
        <LeaderboardLabelRow>
          {LeaderboardLabels.map((label, index) => {
            return (
              <LeaderboardItem key={label} isIndex={index === 0}>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="#C2C2C2">
                  {label}
                </Text>
              </LeaderboardItem>
            )
          })}
        </LeaderboardLabelRow>
        {leaderboardDisplayData.page.map(info => {
          return (
            <LeaderboardItemRow key={info.id}>
              <LeaderboardItem isIndex={true}>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="white" width="auto">
                  {info.id + 1}
                </Text>
              </LeaderboardItem>
              <LeaderboardItem>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="white" width="auto">
                  {shortenAddress(info.walletAddress)}
                </Text>
              </LeaderboardItem>
              <LeaderboardItem>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="white" width="auto">
                  {info.totalReferrals} users
                </Text>
              </LeaderboardItem>
              <LeaderboardItem>
                <Text fontWeight={400} fontSize={[12, 12, 16]} color="white" width="auto">
                  {info.totalFee} ETH
                </Text>
              </LeaderboardItem>
            </LeaderboardItemRow>
          )
        })}
        <PageNavigator>
          <NavigateButton onClick={onClickPrevious}>
            <Arrow src={ArrowLeft} />
          </NavigateButton>
          <Row width="auto">
            {Array(leaderboardDisplayData.pageCount)
              .fill(null)
              .map((_, index) => {
                const selected = selectedPage === index
                const isLast = index === leaderboardDisplayData.pageCount - 1
                return (
                  <PageNumber key={index} selected={selected} isLast={isLast} onClick={() => setSelectedPage(index)}>
                    {index + 1}
                  </PageNumber>
                )
              })}
          </Row>
          <NavigateButton onClick={onClickNext}>
            <Arrow src={ArrowRight} />
          </NavigateButton>
        </PageNavigator>
      </Leaderboard>
    </Container>
  )
}
