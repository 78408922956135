import { ChainId } from '@uniswap/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  // [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.BASE]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.BASE_GOERLI]: '0xceBFe5525583c527AdC87Db1e539835071B4E19f',
  [ChainId.LINEA]: '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F',
  [ChainId.LINEA_GOERLI]: '0xad0FE676261313ce3888618A17F7bc96C0a82Ac8',
  [ChainId.OP_BNB_TESTNET]: '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F',
  [ChainId.OP_BNB]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.ZKSYNC]: '0xF4fd7A9efC0cE52f2024a8b801b7A398876cC3f6',
  [ChainId.ARBITRUM]: '0xAE85dd0A742Bc36C8ADf0931F9e2365Ab2712787',
  [ChainId.ZETA_TESTNET]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.SCROLL_TESTNET]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.BRISE]: '0xc2C2D1EfEA0FC4bFF202E6510768b8e30b559963',
  [ChainId.OMNI_TESTNET]: '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F',
  [ChainId.TAIKO_TESTNET]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.SCROLL]: '0x8d161EB5eB541c09C9589d7C55F17E89AD9d225F',
  [ChainId.MANTA]: '0xb6ea60B3611fcC81FF7CB2f7d44045e86d082e3a'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
