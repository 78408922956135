import { InjectedConnector } from '@web3-react/injected-connector'
import { Web3Provider } from '@ethersproject/providers'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import UNISWAP_LOGO_URL from '../assets/svg/logo.svg'
import { NetworkConnector } from './NetworkConnector'
import { ChainId } from '@uniswap/sdk'

export const NETWORK_URL: { [chainId in ChainId]: string } = {
  [ChainId.BASE]: 'https://mainnet.base.org',
  [ChainId.BASE_GOERLI]: 'https://goerli.base.org',
  [ChainId.LINEA_GOERLI]: 'https://rpc.goerli.linea.build',
  [ChainId.LINEA]: 'https://rpc.linea.build',
  [ChainId.OP_BNB_TESTNET]: 'https://opbnb-testnet-rpc.bnbchain.org',
  [ChainId.OP_BNB]: 'https://opbnb-mainnet-rpc.bnbchain.org',
  [ChainId.ZKSYNC]: 'https://mainnet.era.zksync.io',
  [ChainId.ARBITRUM]: 'https://arb1.arbitrum.io/rpc',
  [ChainId.ZETA_TESTNET]: 'https://zetachain-athens-evm.blockpi.network/v1/rpc/public',
  [ChainId.SCROLL_TESTNET]: 'https://sepolia-rpc.scroll.io',
  [ChainId.BRISE]: 'https://mainnet-rpc.brisescan.com',
  [ChainId.OMNI_TESTNET]: 'https://testnet.omni.network',
  [ChainId.TAIKO_TESTNET]: 'https://rpc.jolnir.taiko.xyz',
  [ChainId.SCROLL]: 'https://rpc.scroll.io',
  [ChainId.MANTA]: 'https://pacific-rpc.manta.network/http'
}

export const NETWORK_CHAIN_ID: ChainId = ChainId.SCROLL

if (typeof NETWORK_URL[NETWORK_CHAIN_ID] === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

export const network = new NetworkConnector({
  urls: {
    [ChainId.SCROLL]: 'https://rpc.scroll.io'
    // [ChainId.OP_BNB]: 'https://opbnb-mainnet-rpc.bnbchain.org'
    // [ChainId.BASE_GOERLI]: 'https://goerli.base.org',
    // [ChainId.LINEA_GOERLI]: 'https://rpc.goerli.linea.build',
    // [ChainId.LINEA]: 'https://rpc.linea.build',
    // [ChainId.OP_BNB_TESTNET]: 'https://opbnb-testnet-rpc.bnbchain.org/'
  }
  // defaultChainId: NETWORK_CHAIN_ID
})

let networkLibrary: Web3Provider | undefined

export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds: [
    1,
    84531,
    59140,
    5611,
    8453,
    59144,
    59144,
    204,
    324,
    42161,
    7001,
    534351,
    32520,
    165,
    167007,
    534352,
    169
  ]
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: { [NETWORK_CHAIN_ID]: NETWORK_URL[NETWORK_CHAIN_ID] },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL[NETWORK_CHAIN_ID],
  appName: 'Pixelswap',
  appLogoUrl: UNISWAP_LOGO_URL
})
